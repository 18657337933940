<template>
  <div>
    <!--begin::Content header-->
    <div
      class="
        position-absolute
        top-0
        right-0
        text-right
        mt-5
        mb-15 mb-lg-0
        flex-column-auto
        justify-content-center
        py-5
        px-10
      "
    ></div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-5 mb-lg-10">
        <a target="_blank" href="https://didongviet.vn/">
          <img src="/media/logos/ddv2.png" class="img-fluid" alt='ddv-logo' />
        </a>
      </div>


      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: errors && errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text">{{ errors }}</div>
        </div>
        <div>
          <b-form-group
            label="Tài khoản"
            label-for="example-input-2"
            label-class="font-size-h6 font-weight-bolder text-dark"
          >
            <b-form-input
              class="form-control form-control-solid py-5 px-6 login-form-input-500"
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback"
              >Vui lòng nhập tài khoản hợp lệ!</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <b-form-group
          id="example-input-group-2"
          label="Mật khẩu"
          label-for="example-input-2"
          label-class="font-size-h6 font-weight-bolder text-dark"
        >
          <b-form-input
            class="form-control form-control-solid py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback"
            >Vui lòng nhập mật khẩu</b-form-invalid-feedback
          >
        </b-form-group>

        <!--begin::Action-->
        <div
          class="
            form-group
            d-flex
            flex-wrap
            justify-content-between
            align-items-center
          "
        >
          <button
            ref="kt_login_signin_submit"
            class="btn btn-danger font-weight-bolder"
          >
            Đăng nhập
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.login-form-input-500 {
  width: 500px;
}
</style>

<script>
import { mapState } from 'vuex';
import { LOGIN, LOGOUT } from '@/core/services/store/modules/auth.module';

import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send login request
      this.$store
        .dispatch(LOGIN, { username, password })
        // go to which page after successfully login
        .then(() => {
          this.$router.push({ name: 'list-products' });
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, TIME_TRIGGER);
          submitButton.classList.remove(
            'spinner',
            'spinner-light',
            'spinner-right'
          );
        })
        .catch(() => {
          submitButton.classList.remove(
            'spinner',
            'spinner-light',
            'spinner-right'
          );
        });
    },
    checkAuthUser() {
      if (this.isAuthenticated) {
        this.$router.push({ name: 'dashboard' });
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },
  created() {
    this.checkAuthUser();
  }
};
</script>
